import React from 'react';
import './Aboutme.css';
import Description1 from 'D:/gbmart/src/assets/image/description-pic.png';
import job from 'D:/gbmart/src/assets/image/icons/board.png';
import Education from 'D:/gbmart/src/assets/image/icons/hat.png';
import Languages from 'D:/gbmart/src/assets/image/icons/globe.png';
import Location from 'D:/gbmart/src/assets/image/icons/location.png';
import Location1 from 'D:/gbmart/src/assets/image/icons/people-1.png';
import Location2 from 'D:/gbmart/src/assets/image/icons/people-2.png';
import Location3 from 'D:/gbmart/src/assets/image/icons/people-3.png';
import Location4 from 'D:/gbmart/src/assets/image/icons/people-4.png';
import Location5 from 'D:/gbmart/src/assets/image/icons/people-5.png';
const Aboutme = () => {
  return (
    <div className=" row d-flex align-items-center " id="description">
        <hr className='my-4'/>
      <div className="col-lg-auto col-xs col-sm-auto col-auto">
        <div>
          <img src={Description1} className="img-fluid" />
        </div>
      </div>
      <div className="col-lg col-xs col-sm-auto col-auto pe-2 ">
        <div>
            <p className='.text-muted mt-3'>ABOUT ME</p>
        <p className="mt-3 mb-0">A Graphic Designer with 6 Yrs  Exp | B.A
         
            Visual Communication, I can help you create a Branded <span className="space"><br/></span> Presentation
            or Post Style/System to make your designs look more consistent and
            professional <span className="space"><br/></span> This will save your time, money and energy in the long
            run <a class="text-color">Read More</a>
          </p>
        </div>
      </div>
      <div className="interest my-4">
      <h6 className="text-gray-dark text-capitilaze">Interests</h6>
      <div className="interest-list d-flex flex-wrap mt-2">
        <div className=" bg-light bg-gradient shadow interest-item  rounded d-flex justify-content-center align-items-center">
        <p className="text-secondary mt-2 py-1 px-2">Technology</p>
        </div>
        <div className="mx-2 bg-light bg-gradient shadow interest-item my-1 rounded">
        <p className="text-secondary mt-2 py-1 px-2">Social network</p>
        </div>
        <div className="mr-2 bg-light bg-gradient shadow interest-item my-1 rounded">
        <p className="text-secondary mt-2 py-1 px-2">Java Developer</p>
        </div>
        <div className="mx-2 bg-light bg-gradient shadow interest-item my-1 rounded">
        <p className="text-secondary mt-2 py-1 px-2">Web Developing</p>
        </div>
      </div>
      </div>
      <div className="align-items-center d-flex mt-4 jobs">
        <img src={job} alt="" className=''/>
        <div className="ms-3">
        <h6 className='mb-0'>Job</h6>
        <p className='mb-0'>Senior developer</p>
        </div>
      </div>
      <div className="align-items-center d-flex mt-4 jobs">
        <img src={Education} alt="" className='' width={35} height={35}/>
        <div className="ms-3">
        <h6 className='mb-0'>Education</h6>
        <p className='mb-0'>B.A. Visual communication</p>
        </div>
      </div>
      <div className="align-items-center d-flex mt-4 jobs">
        <img src={Languages} alt="" className=''/>
        <div className="ms-3">
        <h6 className='mb-0'>Languages</h6>
        <p className='mb-0'>Hindi, Urdu, Engliah</p>
        </div>
      </div>
      <div className="align-items-center d-flex mt-4 jobs">
        <img src={Location} alt="" className=''/>
        <div className="ms-3">
        <h6 className='mb-0'>Location</h6>
        <p className='mb-0'>Washington, D.C., US</p>
        </div>
      </div>

      <div id="people" className=''>
      <h4>People You might Know</h4>
      <div className="my-3">
        <div className="  justify-content-between d-flex align-items-center pb-1 py-2 people">
        <div className="d-flex align-items-center">
            <img src={Location1} alt="" width={40}  height={40}/>
        <div className="ms-2 mt-4">
        <h6>Melinda Deshina</h6>
         <p className='mt-0'>Content Creator</p>
        </div>
        </div>
        <div>
            <button className="follow-btn px-3">Follow</button>
          </div>
        </div>
        

        <div className="justify-content-between d-flex align-items-center pb-1 py-2 people">
        <div className="d-flex align-items-center">
            <img src={Location2} alt="" width={40}  height={40}/>
        <div className="ms-2 mt-4">
        <h6 className='mb-0'>Melinda Deshina</h6>
         <p>Content Creator</p>
        </div>
        </div>
        <div>
            <button className="follow-btn px-3">Follow</button>
          </div>
        </div>
        

        <div className="justify-content-between d-flex align-items-center pb-1 py-2 people">
        <div className="d-flex align-items-center">
            <img src={Location3} alt="" width={40}  height={40}/>
        <div className="ms-2 mt-4">
        <h6>Melinda Deshina</h6>
         <p>Content Creator</p>
        </div>
        </div>
        <div>
            <button className="follow-btn px-3">Follow</button>
          </div>
        </div>
        

        <div className="justify-content-between d-flex align-items-center pb-1 py-2 people">
        <div className="d-flex align-items-center">
            <img src={Location4} alt="" width={40}  height={40}/>
        <div className="ms-2 mt-4">
        <h6>Melinda Deshina</h6>
         <p>Content Creator</p>
        </div>
        </div>
        <div>
            <button className="follow-btn px-3">Follow</button>
          </div>
        </div>
        

        <div className="d-flex justify-content-between align-items-center pb-1 my-2 ">
        <div className="d-flex align-items-center">
            <img src={Location5} alt="" width={40}  height={40}/>
        <div className="ms-2 mt-4">
        <h6>Melinda Deshina</h6>
         <p>Content Creator</p>
        </div>
        </div>
          <div>
            <button className="follow-btn px-3">Follow</button>
          </div>
        
        </div>
        <div className="d-flex justify-content-between align-items-center  pb-1 my-2">
          <div className="d-flex align-items-center">
          <img src={Location5} alt="" width={40}  height={40}/>
            <div className="ms-2 mt-4">
                <h6>Melinda Deshina</h6>
                <p>Content Creator</p>
            </div>
          </div>
          <div>
            <button className="follow-btn px-3">Follow</button>
          </div>
        </div>
      </div>
      </div>
      <hr className='mt-4'/> 
    </div>
  );
}

export default Aboutme
