import React from 'react';
import './Article.css';
import { BsThreeDotsVertical } from "react-icons/bs";
import article1 from 'D:/gbmart/src/assets/image/article.png'
import article2 from 'D:/gbmart/src/assets/image/article-1.png'
import article3 from 'D:/gbmart/src/assets/image/article-2.png'

const Article = () => {
  return (
   <div className="container">
    <div className="d-flex justify-content-between align-items-center mb-3">
    <div className="heading">
        <h4>Popular Articles</h4>
    </div>
    <div>
        <a href="" className='text-color'>Show more ></a>
    </div>
   </div>
   <div className="row align-items-center my-4">
    <div className="col-lg-9">
    <div className="article-text-cover">
            <h4>
              Disney/Pixar’s Soul Leaves Yours Feeling Sore: An Anti-Black Train
              Wreck from Start to End
            </h4>
            <p>
              You regularly caught wind of photography workshops and occasions
              on the off chance that you are keen on photography. Furthermore,
              in some cases....
            </p>
            <p className="date">Dec 26 - 5 min read</p>
          </div>
    </div>
    <div className="col-lg-3">
    <div className="article-img-cover">
            <img src={article1} className=" article-img d-block ms-auto img-fluid" />
            <div className="dots">
              <BsThreeDotsVertical color="#fff" fontSize={"22px"} />
            </div>
            <div class="overlay">
  
            </div>
          </div>
    </div>
   </div>
   <div className="row align-items-center my-4">
    <div className="col-lg-9">
    <div className="article-text-cover">
            <h4>
              Disney/Pixar’s Soul Leaves Yours Feeling Sore: An Anti-Black Train
              Wreck from Start to End
            </h4>
            <p>
              You regularly caught wind of photography workshops and occasions
              on the off chance that you are keen on photography. Furthermore,
              in some cases....
            </p>
            <p className="date">Dec 26 - 5 min read</p>
          </div>
    </div>
    <div className="col-lg-3">
    <div className="article-img-cover">
            <img src={article2} className=" article-img d-block ms-auto img-fluid" />
            <div className="dots">
              <BsThreeDotsVertical color="#fff" fontSize={"22px"} />
            </div>
            <div class="overlay">
  
            </div>
          </div>
    </div>
   </div>
   <div className="row align-items-center my-4">
    <div className="col-lg-9">
    <div className="article-text-cover">
            <h4>
              Disney/Pixar’s Soul Leaves Yours Feeling Sore: An Anti-Black Train
              Wreck from Start to End
            </h4>
            <p>
              You regularly caught wind of photography workshops and occasions
              on the off chance that you are keen on photography. Furthermore,
              in some cases....
            </p>
            <p className="date">Dec 26 - 5 min read</p>
          </div>
    </div>
    <div className="col-lg-3">
    <div className="article-img-cover">
            <img src={article3} className=" article-img d-block ms-auto img-fluid" />
            <div className="dots">
              <BsThreeDotsVertical color="#fff" fontSize={"22px"} />
            </div>
            <div class="overlay">
  
            </div>
          </div>
    </div>
   </div>
   <hr />
   </div>
  )
}

export default Article;
