import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation } from 'swiper';


import { Pagination, Navigation } from 'swiper';
import './Carousel.css';
import img1 from 'D:/gbmart/src/componant/assets/image/img1.jpeg';
import img2 from 'D:/gbmart/src/componant/assets/image/img2.jpeg';
import img3 from 'D:/gbmart/src/componant/assets/image/img3.jpeg';


const Carousel = () => {
  return (
    <div className="container-fluid">
            <Swiper
    modules={[Pagination, Navigation ]} 
    pagination={true}
    navigation={true}
    loopFillGroupWithBlank={true}
     slidesPerView={1} 
     spaceBetween={10} 
     slidesPerGroup={1}
     loop={true}
     className="myswiper"
    
    >
        
       <SwiperSlide>
        <img src={img1} alt='product' className='img-r' height={300}/>
        
       </SwiperSlide>
     <SwiperSlide>
     <img src={img2} alt='product' className='img-r' height={300}/>
     
     </SwiperSlide>
     <SwiperSlide>
     <img src={img3} alt='product' className='img-r' height={300}/>
     </SwiperSlide>
        
    </Swiper>
    </div>

  )
}

export default Carousel;