import React from 'react';
import Description from '../../Description/Description';
import Recommended from '../../Recommended/Recommended';
import Aboutme from '../Aboutme/Aboutme';
import Article from '../Article/Article';
import FeatureCourses from '../FeatureCourses/FeatureCourses';
import Gallery from '../Gallery/Gallery';
import Header from '../Header/Header';
import Instructor from '../Instructor/Instructor';
import Slider from '../Slider/Slider';
import './Hero.css';
import dp1 from 'D:/gbmart/src/assets/image/dp1.png';

const Hero = () => {
  return (
    <div class="container">
  <div class="row">
    <div class="col-sm-3 bg-white aside">
      <div className="container">
      <div className=" profile"> 
      <img src={dp1} alt="" className="img-fluid "/>
      </div>
      <div className="detail">
        <h4 className="mt-2">Micheal J.</h4>
        <p>React Devloper & UI/UX Designer</p>
          <button className="btn btn-primary shadow mt-3">+ Follow</button>
         <button className="btn btn-secondary shadow mt-2">Send Message</button>
         </div>
         <div className="stats mt-5">
        <div className="d-flex justify-content-between align-items-center my-3">
          <h6>Followers</h6>
          <p>180</p>
        </div>
        
        <div className="d-flex justify-content-between align-items-center my-3 ">
          <h6>Articles</h6>
          <p>7</p>
        </div>
      
        <div className="d-flex justify-content-between align-items-center my-3 ">
          <h6>Following</h6>
          <p>153</p>
        </div>
        
        <div className="d-flex justify-content-between align-items-center my-3 ">
          <h6>Courses</h6>
          <p>180</p>
        </div>
        
      </div>
     </div>
     <div className="col col-auto">
     <Aboutme/>
     </div>
     
     </div>
    <div class="col-sm-9">
      <div className="container">
      <div class="row">
        <Header/>
        <FeatureCourses/>
        <Slider/>
        <Article/>
        <Gallery/>
        <Recommended/>
        <Description/>
        <Instructor/>
      </div>
    </div>
  </div>
  </div>
  </div>
  )
}

export default Hero;