import React from 'react';
import Carousel from './componant/Carousel/Carousel';
import Hero from './componant/Hero/Hero';
import Sidebar from './componant/Hero/Sidebar';

const App = () => {
  return (
    <>
  
     <Carousel/> 
     <Hero/>
     <Sidebar/>
    </>
  )
}

export default App
