import React from 'react'
import './Recommended.css';
import Recommended1 from 'D:/gbmart/src/assets/image/recommended.png';
import Recommended2 from 'D:/gbmart/src/assets/image/recommended-1.png';
import Recommended3 from 'D:/gbmart/src/assets/image/recommended-2.png';
import Recommended4 from 'D:/gbmart/src/assets/image/recommended-3.png';
import Recommended5 from 'D:/gbmart/src/assets/image/recommended2.png';
import Recommended6 from 'D:/gbmart/src/assets/image/recommendedb.png';
import Recommended7 from 'D:/gbmart/src/assets/image/recommendedb1.png';
import Recommended8 from 'D:/gbmart/src/assets/image/recommendedb2.png';
import Recommended9 from 'D:/gbmart/src/assets/image/recommended3.png';
import Recommended10 from 'D:/gbmart/src/assets/image/recommendedf.png';
import Recommended11 from 'D:/gbmart/src/assets/image/recommendedf1.png';
import Recommended12 from 'D:/gbmart/src/assets/image/recommendedf2.png';


const Recommended = () => {
  return (
    <div className="container">
        
            <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="heading">
          <h4 className="mb-0">Recomended Reading list</h4>
        </div>
        <div>
          <a className="text-color">Show More ></a>
        </div>
            </div>
            <div className="row g-3">
                <div className="col-lg-4">
                    <div className=" recommended-card bg-white rounded">
                        <img src={ Recommended1 } alt="" className='img-fluid mb-1 px-2 mt-2'/>
                        <div className="d-flex px-2">
                            <img src={Recommended2} alt="" className='img-fluid'/>
                            <img src={Recommended3} alt="" className='img-fluid'/>
                            <img src={Recommended4} alt="" className='img-fluid'/>
                        </div>
                        <div className="text-center mt-2">
                        <p className="ill mb-0">illustrations</p>
                        <p className="article mb-1">28 Articles</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className=" recommended-card bg-white rounded">
                        <img src={ Recommended5 } alt="" className='img-fluid mb-1 px-2 mt-2'/>
                        <div className="d-flex px-2">
                            <img src={Recommended6} alt="" className='img-fluid'/>
                            <img src={Recommended7} alt="" className='img-fluid'/>
                            <img src={Recommended8} alt="" className='img-fluid'/>
                        </div>
                        <div className="text-center mt-2">
                        <p className="ill mb-0">illustrations</p>
                        <p className="article mb-1">28 Articles</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="recommended-card bg-white rounded">
                        <img src={ Recommended9 } alt="" className='img-fluid mb-1 px-2 mt-2'/>
                        <div className="d-flex px-2">
                            <img src={Recommended10} alt="" className='img-fluid'/>
                            <img src={Recommended11} alt="" className='img-fluid'/>
                            <img src={Recommended12} alt="" className='img-fluid'/>
                        </div>
                        <div className="text-center mt-2">
                        <p className="ill mb-0">illustrations</p>
                        <p className="article mb-1">28 Articles</p>
                        </div>
                    </div>
                </div>               
        </div>
        
    </div>
  )
}

export default Recommended;
