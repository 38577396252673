import React from 'react';
import './Header.css';
import Nav from 'react-bootstrap/Nav';


const Header = () => {
  return (
    <>
    <nav>
   <div className="menu-link">
    <ul>
      <li><a href='#'>Home</a></li>
      <li><a href='#'>Courses</a></li>
      <li><a href='#'>Articles</a></li>
      <li><a href='#'>About</a></li>
    </ul>
    </div>
    </nav>         
    </>
  );
}

export default Header;
