import React from 'react';
import './Gallery.css';
import  gallery1 from "D:/gbmart/src/assets/image/gallery.png";
import  gallery2 from "D:/gbmart/src/assets/image/gallery-1.png";
import  gallery3 from "D:/gbmart/src/assets/image/gallery-2.png";
import  gallery4 from "D:/gbmart/src/assets/image/gallery-3.png";
import  gallery5 from "D:/gbmart/src/assets/image/gallery-4.png";


const Gallery = () => {
  return (
    // <!-- Gallery -->
<div className="row">
<h4>Have A Look at my Gallery ‎😃</h4>
  <div className="col-lg-6 col-md-12 mb-4 mt-1 mb-lg-0">
    <img
      src={gallery1}
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />
 </div>

  <div className="col-lg-3 mb-4 mb-lg-0">
    <img
      src={gallery3}
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />

    <img
      src={gallery4}
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />
  </div>

  <div className="col-lg-3 mb-4 mb-lg-0">
    <img
      src={gallery5}
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Waves at Sea"
    />

<img
      src={gallery2}
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
  </div>
  <hr />
</div>
// <!-- Gallery -->

  )
}

export default Gallery
