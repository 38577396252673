import React from 'react'
import './Slider.css';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination, Navigation } from 'swiper';
import Course2 from 'D:/gbmart/src/componant/assets/image/course2.png';
import Course3 from 'D:/gbmart/src/componant/assets/image/course3.png';
import Course4 from 'D:/gbmart/src/componant/assets/image/course.png';
import globe from 'D:/gbmart/src/componant/assets/image/icons/vector-globe.png';
import { BsFillClockFill } from "react-icons/bs";



const Slider = () => {
    return (
    <div className="container">
            <hr></hr>
            <div className="heading mb-5">
           <h4>Featured courses</h4>
          <p >Photoshop complete guide from Zero to Hero</p>
         </div>
            
                <Swiper
        modules={[Pagination, Navigation ]} 
        pagination={true}
        navigation={true}
        loopFillGroupWithBlank={true}
         slidesPerView={4} 
         spaceBetween={40} 
         slidesPerGroup={1}
         loop={true}
         className="myswiper"
         breakpoints={{
             // when window width is >= 768px
             120: {
                
                slidesPerView: 1,
              },
            // when window width is >= 640px
            576: {
              
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
             
              slidesPerView: 2,
            },
             // when window width is >= 992px
             992: {
               
                slidesPerView: 3,
              },
              1200: {
                
                slidesPerView: 4,
              },
              
          }}
        >
            
           <SwiperSlide>
            
           <div className="course-cover bg-white mx-2 ">
         <img src={Course2} alt='product' className='img-r rounded' />
          <div>
            <p className="course-para">Photoshop complete guide from Zero to Hero</p>
            <small>Jordan Bill.</small>
            <hr className="courseLine" />
            <div className="d-flex feature-footer justify-content-between">
              <div className="d-flex align-items-center">
                <img src={globe} />
                <p>English</p>
              </div>
              <div className="d-flex align-items-center">
                <BsFillClockFill color="#838f9b" fontSize={"20px"} />
                <p>01h 46m</p>
              </div>
            </div>
          </div>
        </div>
            
           </SwiperSlide>
         <SwiperSlide>
         
         <div className="course-cover bg-white mx-2">
         <img src={Course3} alt='product' className='img-r rounded' />
          <div>
            <p className="course-para">Photoshop complete guide from Zero to Hero</p>
            <small>Jordan Bill.</small>
            <hr className="courseLine" />
            <div className="d-flex feature-footer justify-content-between">
              <div className="d-flex align-items-center">
                <img src={globe} />
                <p>English</p>
              </div>
              <div className="d-flex align-items-center">
                <BsFillClockFill color="#838f9b" fontSize={"20px"} />
                <p>01h 46m</p>
              </div>
            </div>
          </div>
        </div>
         
         </SwiperSlide>
         <SwiperSlide>
         <div className="course-cover bg-white mx-2">
         <img src={Course4} alt='product' className='img-r rounded' />
          <div>
            <p className="course-para">Photoshop complete guide from Zero to Hero</p>
            <small>Jordan Bill.</small>
            <hr className="courseLine" />
            <div className="d-flex feature-footer justify-content-between">
              <div className="d-flex align-items-center">
                <img src={globe} />
                <p>English</p>
              </div>
              <div className="d-flex align-items-center">
                <BsFillClockFill color="#838f9b" fontSize={"20px"} />
                <p>01h 46m</p>
              </div>
            </div>
          </div>
        </div>
         </SwiperSlide>
            
        </Swiper>
        <hr />
        </div>
       
      )
}

export default Slider;