import React from 'react'
import './Instructor.css';
import { Swiper, SwiperSlide } from 'swiper/react';


import { Pagination, Navigation } from 'swiper';
import instructor1 from 'D:/gbmart/src/componant/assets/image/instructor.png';
import instructor2 from 'D:/gbmart/src/componant/assets/image/instructor1.png';
import instructor3 from 'D:/gbmart/src/componant/assets/image/instructor2.png';
import instructor4 from 'D:/gbmart/src/componant/assets/image/instructor3.png';



const Instructor = () => {
    return (
    <div className="container">
            <div className="heading mb-5">
           <h4>TOP INSTRUCTORS</h4>
         
         </div>
            
                <Swiper
        modules={[Pagination, Navigation ]} 
        pagination={true}
        navigation={true}
        loopFillGroupWithBlank={true}
         slidesPerView={4} 
         spaceBetween={40} 
         slidesPerGroup={1}
         loop={true}
         className="myswiper"
         breakpoints={{
             // when window width is >= 768px
             120: {
                
                slidesPerView: 1,
              },
            // when window width is >= 640px
            576: {
              
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
             
              slidesPerView: 2,
            },
             // when window width is >= 992px
             992: {
               
                slidesPerView: 3,
              },
              1200: {
                
                slidesPerView: 4,
              },
              
          }}
        >
            
           <SwiperSlide>
            
           <div className="instructor-cover mx-2 ">
         <img src={instructor1} alt='product' className='mx-auto d-block rounded img-fluid' />
          <div className='text-center'>
            <p className="course-para">Melinda Deshina</p>
            <small>Content Creator</small>
            <hr className="courseLine" />
            <button className='btn-instructor '>Follow</button>
          </div>
        </div>
            
           </SwiperSlide>
         <SwiperSlide>
         
         <div className="instructor-cover mx-2">
         <img src={instructor2} alt='product' className='mx-auto d-block rounded img-fluid' />
          <div className='text-center'>
            <p className="course-para">Melinda Deshina</p>
            <small>Content Creator</small>
            <hr className="courseLine" />
            <button className='btn-instructor '>Follow</button>
          </div>
        </div>
         
         </SwiperSlide>
         <SwiperSlide>
         <div className="instructor-cover mx-2">
         <img src={instructor3} alt='product' className='mx-auto d-block rounded img-fluid' />
          <div className='text-center'>
            <p className="course-para">Melinda Deshina</p>
            <small>Content Creator</small>
            <hr className="courseLine" />
            <button className='btn-instructor '>Follow</button>
          </div>
        </div>
         </SwiperSlide>
         <SwiperSlide>
         <div className="instructor-cover mx-2">
         <img src={instructor4} alt='product' className='mx-auto d-block rounded img-fluid' />
          <div className='text-center'>
            <p className="course-para">Melinda Deshina</p>
            <small>Content Creator</small>
            <hr className="courseLine" />
            <button className='btn-instructor '>Follow</button>
          </div>
        </div>
         </SwiperSlide>
            
        </Swiper>
        <hr className='mt-5'/>
        </div>
       
      )
}

export default Instructor;