import React from 'react';
import './FeatureCourses.css';
import { BsFillClockFill } from "react-icons/bs";
import img4 from 'D:/gbmart/src/componant/assets/image/maxresdefault.png';
import star from 'D:/gbmart/src/componant/assets/image/Star.png';
import globe from 'D:/gbmart/src/componant/assets/image/icons/vector-globe.png'

const FeatureCourses = () => {
  return (
   <div className='' id="FeatureCourses">
    <div className="d-flex justify-content-between aline-items-center m-3">
        <div className="heading">
        <h4>Featured courses</h4>
          <p>Photoshop complete guide from Zero to Hero</p>
        </div>
        <div>
          <a className="text-color">Show More </a>
        </div>
    </div>
    <div class="card mb-3">
    <div className="row shadow ">
        <div className="col-lg-4 ">
            <img src={img4} alt="" className='img4'/>
        </div>
        <div className="col-lg-8 bg-white  py-3 set-radius rounded">
          <div className="">
            <div className="badge">
              <img
                src={star}
                className="py-1 mx-1"
                width={"12px"}
              />
              <span className="pt-1">Featured</span>
            </div>
            <h3 className="mt-3">Photoshop complete guide from Zero to Hero</h3>
            <p className="mt-3">Micheal James</p>
          </div>
          <hr className="line" />
          <div className="d-flex feature-footer justify-content-between">
            <div className="d-flex align-items-center">
              <img src={globe} />
              <p>English</p>
            </div>
            <div className="d-flex align-items-center">
              <BsFillClockFill color="#838f9b" fontSize={"20px"} />
              <p>01h 46m</p>
            </div>
            <div className="d-flex  align-items-center">
              <img src={star} />
              <p className="text-dark">4.5</p>
            </div>
          </div>
        </div>
    </div>
   </div>
   </div>
  )
}

export default FeatureCourses;
{/* <div class="card mb-3">
  <div class="row g-0">
    <div class="col-md-4">
      <img src="..." class="img-fluid rounded-start" alt="...">
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
    </div>
  </div>
</div>  */}